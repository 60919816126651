<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>借贷</el-breadcrumb-item>
                <el-breadcrumb-item>列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div>
                <vy-search-box v-model="searchVal" :btn-click="getData" placeholder="请输入内容"></vy-search-box>
            </div>
            <div class="_add" v-if="$userLevel == 1">
                <button @click="openAdd">添加借贷人</button>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <!-- @cell-mouse-enter="rowHover" -->
            <el-table :data="tableData" row-key="id" border @row-click="rowClick" ref="tab">
                <el-table-column prop="name" align="center" label="名字">
                    <template slot-scope="{ row }">
                        <span>{{ row.pid == 0 ? row.name : "" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="please_return_time" align="center" label="还款日">
                    <template slot-scope="{ row }">
                        <span>{{
                            typeof row.please_return_time == "number"
                                ? `每月 ${row.please_return_time} 日`
                                : row.please_return_time
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="month_please_return" align="center" label="应还款"></el-table-column>
                <el-table-column prop="return_money" align="center" label="已还金额"></el-table-column>
                <el-table-column prop="profit" align="center" label="本期利润"></el-table-column>
                <el-table-column prop="term_num" align="center" label="还款期数">
                    <template slot-scope="{ row }">
                        <span>{{ row.pid == 0 ? `${row.return_term_num} 期` : `第 ${row.term_num} 期` }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="return_status" align="center" label="还款状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: return_status(row.return_status).color }">{{
                            return_status(row.return_status).str
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate" v-if="$userLevel == 1">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete" v-if="row.pid == 0 && $userLevel == 1">
                            <button @click="del(row)">删除</button>
                        </div>
                        <div class="_look">
                            <button
                                @click="
                                    look(row);
                                    is_look = true;
                                "
                            >
                                查看详情
                            </button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑借贷人信息 -->
        <el-dialog
            :title="is_look ? '查看' : form.id ? '借贷人编辑' : '借贷人添加'"
            :visible.sync="elDialogShow"
            width="60%"
            @closed="
                formDisabled = false;
                is_look = false;
            "
            :before-close="bfc"
        >
            <el-form label-width="120px" size="small" :disabled="formDisabled">
                <el-form-item label="名字">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item label="账号">
                    <el-select v-model="form.account" placeholder="选择关联账号">
                        <el-option v-for="el in accountOpt" :key="el" :label="el" :value="el"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="总金额">
                    <el-input-number v-model="form.loan" :min="0" :step="100"></el-input-number>
                </el-form-item>
                <el-form-item label="手续费(%)">
                    <el-input-number v-model="form.commission" :min="0" :precision="2" :step="0.1" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="日利息(%)">
                    <el-input-number
                        v-model="form.date_interest"
                        :min="0"
                        :precision="2"
                        :step="0.1"
                        :max="100"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="月利息(%)">
                    <el-input-number
                        v-model="form.month_interest"
                        :min="0"
                        :precision="2"
                        :step="0.1"
                        :max="100"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="逾期日利息(%)">
                    <el-input-number
                        v-model="form.term_date_interest"
                        :min="0"
                        :precision="2"
                        :step="0.1"
                        :max="100"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="逾期月利息(%)">
                    <el-input-number
                        v-model="form.term_month_interest"
                        :min="0"
                        :precision="2"
                        :step="0.1"
                        :max="100"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="约定还款时间">
                    <el-input-number v-model="form.please_return_time" :min="1" :max="31"></el-input-number>
                    <span style="font-size: 12px; color: #ccc">每个月的xx号</span>
                </el-form-item>
                <el-form-item label="汇款时间">
                    <el-date-picker v-model="form.give_time" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="多少期" v-if="!form.id">
                    <el-input-number v-model="form.return_term_num" :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="汇款方式">
                    <el-input v-model.trim="form.give_function"></el-input>
                </el-form-item>
                <el-form-item label="是否全还" v-if="form.id">
                    <el-radio-group fill="#7781f1" v-model="form.return_status">
                        <el-radio-button label="未还"></el-radio-button>
                        <el-radio-button label="未还清"></el-radio-button>
                        <el-radio-button label="还清"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="!is_look">
                <el-button @click="elDialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑某一期 -->
        <el-dialog
            :title="is_look ? '查看' : '编辑'"
            :visible.sync="elDialogShow_two"
            width="60%"
            @closed="
                form_twoDisabled = false;
                is_look = false;
            "
            :before-close="bfc"
        >
            <el-form label-width="120px" size="small" :disabled="form_twoDisabled">
                <el-form-item label="还款期数">
                    <span>第 {{ form_two.term_num }} 期</span>
                </el-form-item>
                <el-form-item label="约定还款时间">
                    <el-date-picker disabled v-model="form_two.please_return_time" type="date" value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际还款时间">
                    <el-date-picker v-model="form_two.return_time" type="date" value-format="yyyy-MM-dd"> </el-date-picker>
                </el-form-item>
                <el-form-item label="还款金额">
                    <el-input-number v-model="form_two.return_money" :min="0" :precision="2" :step="100"></el-input-number>
                </el-form-item>
                <el-form-item label="本期利润">
                    <el-input-number disabled v-model="form_two.profit"></el-input-number>
                </el-form-item>
                <el-form-item label="异常金额">
                    <el-input-number disabled v-model="form_two.error_money"></el-input-number>
                </el-form-item>
                <el-form-item label="逾期日数">
                    <el-input-number disabled v-model="form_two.term_day" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="逾期月数">
                    <el-input-number disabled v-model="form_two.term_month" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="还款方式">
                    <el-input v-model="form_two.return_function"></el-input>
                </el-form-item>
                <el-form-item label="还款状态">
                    <el-radio-group fill="#7781f1" v-model="form_two.return_status">
                        <el-radio-button label="未还"></el-radio-button>
                        <el-radio-button label="未还清"></el-radio-button>
                        <el-radio-button label="还清"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="!is_look">
                <el-button @click="elDialogShow_two = false">取 消</el-button>
                <el-button type="primary" @click="yes_two">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import VySearchBox from "@/components/customs/VySearchBox.vue";
export default {
    components: { VySearchBox },
    data() {
        return {
            tableData: [],
            form: {
                name: "",
                loan: 100,
                account: "",
                commission: "",
                date_interest: "",
                month_interest: "",
                term_date_interest: "",
                term_month_interest: "",
                give_function: "",
                give_time: "",
                please_return_time: 1,
                return_term_num: 1,
            },
            elDialogShow: false,
            form_two: {},
            elDialogShow_two: false,
            formDisabled: false,
            form_twoDisabled: false,
            // 选择账号
            accountOpt: [],
            is_look: false,
            searchVal: "",
        };
    },
    async created() {
        this.getData();
        this.accountOpt = await this.getAccount();
    },
    methods: {
        // 获取数据
        async getData(searchVal = this.searchVal) {
            let { data } = await axios.get("/api/loan/show", { params: { searchVal } });
            this.tableData = data;
        },
        // 获取所有账号
        async getAccount() {
            let { data } = await axios.get("/api/project_pace/account");
            return data;
        },
        // 打开添加
        openAdd() {
            this.form = {
                name: "",
                loan: 100,
                account: "",
                commission: "",
                date_interest: "",
                month_interest: "",
                term_date_interest: "",
                term_month_interest: "",
                give_function: "",
                give_time: "",
                please_return_time: 1,
                return_term_num: 1,
            };
            this.elDialogShow = true;
        },
        // 打开编辑
        openSet(row) {
            if (row.pid == 0) {
                function fn(x) {
                    return typeof x == "string" ? Number(x.replace("%", "")) : 0;
                }
                // 编辑借贷人,相比添加少了还款期数，多了id和return_status
                this.form = {
                    id: row.id,
                    name: row.name,
                    loan: row.loan,
                    account: row.account,
                    commission: fn(row.commission),
                    date_interest: fn(row.date_interest),
                    month_interest: fn(row.month_interest),
                    term_date_interest: fn(row.term_date_interest),
                    term_month_interest: fn(row.term_month_interest),
                    give_function: row.give_function,
                    give_time: row.give_time,
                    please_return_time: row.please_return_time,
                    return_status: this.return_status(row.return_status).str,
                    return_term_num: row.return_term_num,
                };
                this.elDialogShow = true;
            } else {
                // 编辑某一期
                this.form_two = {
                    ...row,
                    return_status: this.return_status(row.return_status).str,
                };
                this.elDialogShow_two = true;
            }
        },
        // 编辑或添加借贷人
        async yes() {
            const loading = this.$loading({
                lock: true,
                text: "加载中，请稍后......",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            // 防止请求过快出现闪屏情况
            let timer = setTimeout(() => {
                clearTimeout(timer);
                timer = null;
            }, 500);
            let form = { ...this.form };
            form.return_status = this.return_status(form.return_status).num;
            for (const k in form) {
                if (form[k] === 0) {
                    form[k] = "";
                }
            }
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/loan/fupdate ", qs.stringify(form));
            } else {
                // 添加
                form.children = this.makeTime(form.give_time, form.please_return_time, form.return_term_num);
                var { data } = await axios.post("/api/loan/add", qs.stringify(form));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            if (timer) {
                setTimeout(() => {
                    loading.close();
                }, 500);
            } else {
                loading.close();
            }
            this.elDialogShow = false;
            this.getData();
        },
        // 编辑某一期
        async yes_two() {
            let form = { ...this.form_two };
            form.return_status = this.return_status(form.return_status).num;
            let { data } = await axios.put("/api/loan/edit", qs.stringify(form));
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.elDialogShow_two = false;
            this.getData();
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/loan/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.getData();
        },
        // 处理时间的方法
        makeTime(giveTime = "xxxx-xx-xx", day = 1, dayNum = 1) {
            // giveTime 汇款的时间；
            // day xx号还；
            // dayNum 分多少期；
            // day=String(day).length<=1?'0'+day:day
            let timer = new Date(giveTime);
            let arr = [];
            for (let i = 0; i < dayNum; i++) {
                let y = timer.getFullYear();
                let m = timer.getMonth() + i + 2;
                if (m > 12) {
                    let cishu = 0;
                    for (let index = 0; index >= 0; index++) {
                        if (m <= 12) {
                            break;
                        } else {
                            m = m - 12;
                            cishu++;
                        }
                    }
                    y = y + cishu;
                }
                arr.push({
                    term_num: i + 1,
                    please_return_time: `${y}-${m < 10 ? "0" + m : m}-${day < 10 ? "0" + day : day}`,
                });
            }
            return arr;
        },
        // 查看详情
        look(row) {
            if (row.pid == 0) {
                this.formDisabled = true;
                function fn(x) {
                    return typeof x == "string" ? Number(x.replace("%", "")) : 0;
                }
                // 编辑借贷人,相比添加少了还款期数，多了id和return_status
                this.form = {
                    id: row.id,
                    name: row.name,
                    loan: row.loan,
                    account: row.account,
                    commission: fn(row.commission),
                    date_interest: fn(row.date_interest),
                    month_interest: fn(row.month_interest),
                    term_date_interest: fn(row.term_date_interest),
                    term_month_interest: fn(row.term_month_interest),
                    give_function: row.give_function,
                    give_time: row.give_time,
                    please_return_time: row.please_return_time,
                    return_status: row.return_status,
                };
                this.elDialogShow = true;
            } else {
                this.form_twoDisabled = true;
                // 编辑某一期
                this.form_two = { ...row };
                this.elDialogShow_two = true;
            }
        },
        // 还款状态
        return_status(val) {
            let str = "";
            let color = "";
            let num = 0;
            if (typeof val == "number") {
                switch (val) {
                    case 0:
                        str = "未还";
                        color = "#ccc";
                        break;
                    case 1:
                        str = "未还清";
                        color = "#fe7c96";
                        break;
                    case 2:
                        str = "还清";
                        color = "#7781f1";
                        break;
                    default:
                        break;
                }
            } else if (typeof val == "string") {
                switch (val) {
                    case "未还":
                        num = 0;
                        color = "#ccc";
                        break;
                    case "未还清":
                        num = 1;
                        color = "#fe7c96";
                        break;
                    case "还清":
                        num = 2;
                        color = "#7781f1";
                        break;
                    default:
                        break;
                }
            }

            return { str, color, num };
        },
        // 点击行
        rowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
        // 鼠标悬浮某一行
        // rowHover(row, column, cell, event) {
        //     if (row.pid == 0) {
        //         this.tableData.forEach(e => {
        //             this.$refs["tab"].toggleRowExpansion(e, false);
        //         });
        //         this.$refs["tab"].toggleRowExpansion(row, true);
        //     }
        // },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
